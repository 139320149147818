import {
  type Action,
  type CryptoCurrencyDataSource,
  type FiatCurrency,
  setActionPrices,
} from '@0xtorch/core'
import type {
  Chain,
  getBlockDatas,
  parseExtrinsicToActions,
} from '@0xtorch/polkadot'
import {
  FailedToAnalyzePolkadotChainException,
  FailedToFetchPolkadotBlockDataException,
  FailedToFetchPolkadotIndexDataException,
  divideArrayIntoChunks,
} from '@pkg/basic'
import {
  type AccountPolkadot,
  getPolkadotIndexes,
  ignoreLockedPolkadotBlocks,
  savePolkadotAccountIndexes,
  savePolkadotActions,
} from '@pkg/database-portfolio'
import { accountTable } from '@pkg/database-portfolio/src/schema'
import { eq } from 'drizzle-orm'
import type { RunToDatabase } from './types'

const analyzingPolkadotChains = new Set<number>()

type AnalyzePolkadotAccountsParameters = {
  readFromDatabase: RunToDatabase
  writeToDatabase: RunToDatabase
  getToken?: () => Promise<string | null>
  startTime: number
  chain: Chain
  accounts: readonly AccountPolkadot[]
  fiat: FiatCurrency
  cryptoCurrencyDataSource: CryptoCurrencyDataSource
  getPolkadotBlockDatas: typeof getBlockDatas
  parsePolkadotExtrinsicToActions: (
    parameters: Parameters<typeof parseExtrinsicToActions>['0'],
  ) => Promise<ReturnType<typeof parseExtrinsicToActions>>
  onStart: (accountIdSet: Set<number>) => void
  onEnd: () => void
  onFetchedAccount: (accountId: number) => void
  onStartAnalyzeBlock: (blockNumberSet: Set<number>) => void
  onFetchedBlock: (blockNumber: number) => void
  onAnalyzedBlock: (blockNumber: number) => void
  onError: (error: unknown) => void
}

export const analyzePolkadotAccounts = async ({
  readFromDatabase,
  writeToDatabase,
  getToken,
  startTime,
  chain,
  accounts,
  fiat,
  cryptoCurrencyDataSource,
  getPolkadotBlockDatas,
  parsePolkadotExtrinsicToActions,
  onStart,
  onEnd,
  onFetchedAccount,
  onStartAnalyzeBlock,
  onFetchedBlock,
  onAnalyzedBlock,
  onError,
}: AnalyzePolkadotAccountsParameters) => {
  if (analyzingPolkadotChains.has(chain.id)) {
    return
  }
  analyzingPolkadotChains.add(chain.id)
  onStart(new Set(accounts.map(({ id }) => id)))
  try {
    // 同一 polkadot chain の address 一覧取得
    const addressRows = await readFromDatabase((database) =>
      database.database
        .select({
          address: accountTable.polkadotAddress,
        })
        .from(accountTable)
        .where(eq(accountTable.polkadotChainId, chain.id)),
    )
    const relatedAddresses = new Set<string>()
    for (const row of addressRows) {
      if (row.address === null) {
        continue
      }
      relatedAddresses.add(row.address)
    }

    // account 毎の event history を取得
    for (const account of accounts) {
      // account data 取得間隔が 1 時間未満の場合はスキップ
      if (
        Date.now() - (account.lastSyncedAt?.getTime() ?? 0) <
        1000 * 60 * 60
      ) {
        // account data 取得完了 callback
        onFetchedAccount(account.id)
        continue
      }

      const eventIndexes = await chain.explorer
        .getAddressEvents({
          address: account.polkadotAddress,
          fromBlock: (account.polkadotToBlock ?? 0) + 1,
          toBlock: Date.now(),
          createHeaders:
            getToken === undefined
              ? undefined
              : async () => {
                  const token = await getToken()
                  if (token === null) {
                    return {} as Record<string, string>
                  }
                  return {
                    Authorization: `Bearer ${token}`,
                  }
                },
        })
        .catch((error) => {
          onError(
            new FailedToFetchPolkadotIndexDataException(
              error,
              chain.name,
              account.polkadotAddress,
            ),
          )
          return undefined
        })

      if (eventIndexes !== undefined) {
        // DB に保存
        const extrinsicMap = new Map<
          string,
          {
            blockNumber: number
            extrinsicIndex: number | null
            timestamp: Date
          }
        >()
        for (const eventIndex of eventIndexes) {
          if (eventIndex.block_timestamp < startTime) {
            continue
          }
          const blockNumber = Number(eventIndex.event_index.split('-')[0])
          const extrinsicIndex = eventIndex.extrinsic_index.includes('-')
            ? Number(eventIndex.extrinsic_index.split('-')[1])
            : null
          const key =
            extrinsicIndex === null
              ? blockNumber.toString()
              : `${blockNumber}-${extrinsicIndex}`
          if (!extrinsicMap.has(key)) {
            extrinsicMap.set(key, {
              blockNumber,
              extrinsicIndex,
              timestamp: new Date(eventIndex.block_timestamp),
            })
          }
        }
        const extrinsics = [...extrinsicMap.values()]
        await writeToDatabase((database) =>
          savePolkadotAccountIndexes({
            database,
            accountId: account.id,
            chainId: chain.id,
            extrinsics,
          }),
        )
      }

      // account data 取得完了 callback
      onFetchedAccount(account.id)
    }

    // analyzed = false な extrinsic data を DB から取得
    const { extrinsics } = await readFromDatabase((database) =>
      getPolkadotIndexes({
        database,
        chainId: chain.id,
        analyzed: false,
      }),
    )

    // extrinsics.length が 0 の場合は終了
    if (extrinsics.length === 0) {
      return
    }

    // 該当 extrinsic を source とする isLocked=true の action が存在する tx は除外する
    // 除外した tx について、 accountPolkadotExtrinsicRelation.analyzed を true に更新
    const blockMap = new Map<
      number,
      {
        blockNumber: number
        timestamp: number
        extrinsicIndexes: Set<number | undefined>
      }
    >()
    for (const { blockNumber, extrinsicIndex, timestamp } of extrinsics) {
      const block = blockMap.get(blockNumber) ?? {
        blockNumber,
        timestamp,
        extrinsicIndexes: new Set<number | undefined>(),
      }
      block.extrinsicIndexes.add(extrinsicIndex)
      blockMap.set(blockNumber, block)
    }
    const blocks = [...blockMap.values()].map(
      ({ blockNumber, extrinsicIndexes }) => ({
        blockNumber,
        extrinsicIndexes: [...extrinsicIndexes].map((index) =>
          index === null ? undefined : index,
        ),
      }),
    )
    const blockNumbers = blocks.map(({ blockNumber }) => blockNumber)
    const ignoreBlockNumberSet = await writeToDatabase((database) =>
      ignoreLockedPolkadotBlocks({ database, chainId: chain.id, blockNumbers }),
    )
    const targetBlocks = blocks.filter(
      ({ blockNumber }) => !ignoreBlockNumberSet.has(blockNumber),
    )

    // block 解析開始 callback
    onStartAnalyzeBlock(
      new Set(targetBlocks.map(({ blockNumber }) => blockNumber)),
    )

    // block data を 100件毎に分割して 100 件毎処理
    const chunkSize = Math.min(100, chain.rpcEndpoints.length * 10)
    for (const blockChunk of divideArrayIntoChunks(targetBlocks, chunkSize)) {
      // block data 一括取得
      const blockDatas = await getPolkadotBlockDatas({
        chain,
        blocks: blockChunk,
        onFetched: (blockNumber) => {
          onFetchedBlock(blockNumber)
        },
      }).catch((error) => {
        for (const { blockNumber } of blockChunk) {
          onError(
            new FailedToFetchPolkadotBlockDataException(
              error,
              chain.name,
              blockNumber,
            ),
          )
        }
        return undefined
      })

      if (blockDatas === undefined) {
        continue
      }

      // block > extrinsic data 解析
      const actions: Action[] = []
      for (const blockData of blockDatas) {
        const { blockNumber, events: extrinsicEvents } = blockData
        const block = blockMap.get(blockNumber)
        if (block === undefined) {
          continue
        }
        for (const [extrinsicIndex, events] of extrinsicEvents) {
          const actionsByExtrinsic = await parsePolkadotExtrinsicToActions({
            chain,
            blockNumber,
            timestamp: block.timestamp,
            extrinsicIndex,
            events,
            relatedAddresses,
          })
          actions.push(...actionsByExtrinsic)
        }

        // block 解析完了 callback
        onAnalyzedBlock(blockNumber)
      }

      // 生成した action の価格設定
      const pricedActions = await setActionPrices({
        actions,
        fiat,
        dataSource: cryptoCurrencyDataSource,
      })

      // DB に保存
      await writeToDatabase((database) =>
        savePolkadotActions({
          database,
          chainId: chain.id,
          blocks: blockChunk,
          actions: pricedActions,
        }),
      )
    }
  } catch (error) {
    onError(new FailedToAnalyzePolkadotChainException(error, chain.name))
  } finally {
    analyzingPolkadotChains.delete(chain.id)

    // 処理完了を callback
    onEnd()
  }
}
