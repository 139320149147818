import type { Nft } from '@0xtorch/core'
import {
  type AnalyzeDataSource,
  type Erc20Token,
  type EvmAddress,
  type JsonAnalyzer,
  type LowerHex,
  analyzerSchema,
  createEvmAddressId,
  evmAddressSchema,
} from '@0xtorch/evm'
import { divideArrayIntoChunks } from '@pkg/basic'
import type { AssetFetchClient } from '@pkg/datasource-client'
import { AbiEvent, AbiFunction } from 'abitype/zod'
import type { AppType as DatasourceAppType } from 'datasource-api'
import type { AppType as EvmTokenAppType } from 'evm-token-worker'
import { hc } from 'hono/client'
import { z } from 'zod'

const addressCache = new Map<number, Map<LowerHex, EvmAddress>>()

const erc20TokenCache = new Map<number, Map<LowerHex, Erc20Token>>()

const eventAbiCache = new Map<
  LowerHex,
  { signature: LowerHex; indexedCount: number; abi: string }[]
>()

const functionAbiCache = new Map<LowerHex, string[]>()

const jsonAnalyzerCache = new Map<LowerHex, JsonAnalyzer[]>()

const nftCache = new Map<string, Nft>()

type CreateEvmAnalyzeDataSourceParameters = {
  readonly assetFetchClient: AssetFetchClient
  readonly datasourceApiEndpoint: string
  readonly evmTokenApiEndpoint: string
}

export const createEvmAnalyzeDataSource = ({
  assetFetchClient,
  datasourceApiEndpoint,
  evmTokenApiEndpoint,
}: CreateEvmAnalyzeDataSourceParameters): AnalyzeDataSource => {
  const datasourceClient = hc<DatasourceAppType>(datasourceApiEndpoint)
  const evmTokenClient = hc<EvmTokenAppType>(evmTokenApiEndpoint)

  return {
    getAddresses: async ({ chainId, addresses }): Promise<EvmAddress[]> => {
      const cache = addressCache.get(chainId) ?? new Map<LowerHex, EvmAddress>()
      const newAddresses = addresses.filter((address) => !cache.has(address))
      const notFoundAddressSet = new Set<LowerHex>()
      if (newAddresses.length > 0) {
        const responses = await Promise.all(
          newAddresses.map((address) =>
            assetFetchClient.get(`/evms/addresses/${chainId}/${address}.json`),
          ),
        )
        for (const [index, response] of responses.entries()) {
          if (!response.ok && response.status !== 404) {
            throw new Error(
              `Failed to get address: ${response.status} ${response.statusText}`,
            )
          }
          const address = newAddresses[index]
          if (response.status === 404) {
            notFoundAddressSet.add(address)
            continue
          }
          const result = await response.json()
          const parsed = evmAddressSchema.safeParse(result)
          if (!parsed.success) {
            console.debug(parsed.error)
            cache.set(address, { chainId, address })
          } else {
            cache.set(address, parsed.data)
          }
        }
      }
      if (notFoundAddressSet.size > 0) {
        const addressesChunks = divideArrayIntoChunks(
          [...notFoundAddressSet],
          40,
        )
        const responses = await Promise.all(
          addressesChunks.map((addressesChunk) =>
            datasourceClient.v1.evm.chain[':chainId'].address.$get({
              param: {
                chainId: chainId.toString(),
              },
              query: {
                addresses: [...addressesChunk],
              },
            }),
          ),
        )
        for (const response of responses) {
          if (!response.ok) {
            throw new Error(
              `Failed to get addresses: ${response.status} ${response.statusText}`,
            )
          }
          const result = await response.json()
          for (const address of result) {
            cache.set(address.address, address)
          }
        }
      }
      addressCache.set(chainId, cache)
      return addresses.map(
        (address) => cache.get(address) ?? { chainId, address },
      )
    },

    getErc20Tokens: async ({ chainId, addresses }): Promise<Erc20Token[]> => {
      const cache =
        erc20TokenCache.get(chainId) ?? new Map<LowerHex, Erc20Token>()
      const cachedAddresses = new Set<LowerHex>()
      const newAddresses: LowerHex[] = []
      for (const address of addresses) {
        if (cache.has(address)) {
          cachedAddresses.add(address)
        } else {
          newAddresses.push(address)
        }
      }
      if (newAddresses.length > 0) {
        const addressesChunks = divideArrayIntoChunks(newAddresses, 50)
        const responses = await Promise.all(
          addressesChunks.map((addresses) =>
            evmTokenClient.v1.erc20[':chainId'].$get({
              param: { chainId: chainId.toString() },
              query: { address: [...addresses] },
            }),
          ),
        )
        for (const response of responses) {
          if (!response.ok) {
            throw new Error(
              `Failed to get erc20 tokens: ${response.status} ${response.statusText}`,
            )
          }
          const result = await response.json()
          for (const token of result) {
            cache.set(token.address, token)
            cachedAddresses.add(token.address)
          }
        }
      }
      const notRegisteredAddresses = addresses.filter(
        (address) => !cachedAddresses.has(address),
      )
      if (notRegisteredAddresses.length > 0) {
        for (const address of notRegisteredAddresses) {
          const response = await evmTokenClient.v1.erc20[':chainId'][
            ':address'
          ].$post({
            param: { chainId: chainId.toString(), address },
          })
          if (!response.ok) {
            throw new Error(
              `Failed to get new erc20 tokens: ${response.status} ${response.statusText}`,
            )
          }
          const token = await response.json()
          cache.set(token.address, token)
        }
      }
      erc20TokenCache.set(chainId, cache)
      return addresses.map(
        (address) =>
          cache.get(address) ?? {
            symbol: '',
            address,
            name: '',
            decimals: 0,
            currency: {
              symbol: '',
              type: 'CryptoCurrency',
              id: createEvmAddressId({ chainId, address }),
              name: '',
              updatedAt: 0,
            },
          },
      )
    },

    getEventAbis: async ({
      signatures,
    }): Promise<
      { signature: LowerHex; indexedCount: number; abi: string }[]
    > => {
      const newSignatures = signatures.filter(
        (signature) => !eventAbiCache.has(signature),
      )
      if (newSignatures.length > 0) {
        const responses = await Promise.all(
          newSignatures.map((signature) =>
            assetFetchClient.get(`/evms/events/${signature}.json`),
          ),
        )
        for (const [index, response] of responses.entries()) {
          if (!response.ok && response.status !== 404) {
            throw new Error(
              `Failed to get event abi: ${response.status} ${response.statusText}`,
            )
          }
          const signature = newSignatures[index]
          const result = response.status === 404 ? [] : await response.json()
          const parsed = z.array(AbiEvent).safeParse(result)
          if (!parsed.success) {
            console.debug(parsed.error)
            eventAbiCache.set(signature, [])
            continue
          }
          const events: {
            signature: LowerHex
            indexedCount: number
            abi: string
          }[] = []
          for (const abi of parsed.data) {
            events.push({
              signature,
              indexedCount: abi.inputs.filter((input) => input.indexed === true)
                .length,
              abi: JSON.stringify(abi),
            })
          }
          eventAbiCache.set(signature, events)
        }
      }
      return signatures.flatMap(
        (signature) => eventAbiCache.get(signature) ?? [],
      )
    },

    getFunctionAbi: async ({ functionId }): Promise<string[]> => {
      if (!functionAbiCache.has(functionId)) {
        const response = await assetFetchClient.get(
          `/evms/functions/${functionId}.json`,
        )
        if (!response.ok && response.status !== 404) {
          throw new Error(
            `Failed to get function abi: ${response.status} ${response.statusText}`,
          )
        }
        const result = response.status === 404 ? [] : await response.json()
        const parsed = z.array(AbiFunction).safeParse(result)
        if (!parsed.success) {
          console.debug(parsed.error)
          functionAbiCache.set(functionId, [])
        } else {
          functionAbiCache.set(
            functionId,
            parsed.data.map((abi) => JSON.stringify(abi)),
          )
        }
      }
      return functionAbiCache.get(functionId) ?? []
    },

    getJsonAnalyzer: async ({ functionId }): Promise<JsonAnalyzer[]> => {
      if (!jsonAnalyzerCache.has(functionId)) {
        const response = await assetFetchClient.get(
          `/evms/analyzers/${functionId}.json`,
        )
        if (!response.ok) {
          if (response.status === 404) {
            jsonAnalyzerCache.set(functionId, [])
          } else {
            throw new Error(
              `Failed to get json analyzer: ${response.status} ${response.statusText}`,
            )
          }
        } else {
          const result = await response.json()
          const parsed = z.array(analyzerSchema).safeParse(result)
          if (!parsed.success) {
            console.debug(parsed.error)
            jsonAnalyzerCache.set(functionId, [])
          } else {
            jsonAnalyzerCache.set(functionId, parsed.data)
          }
        }
      }
      return jsonAnalyzerCache.get(functionId) ?? []
    },

    getNfts: async ({ ids }): Promise<Nft[]> => {
      const newIds = ids.filter((id) => !nftCache.has(id))
      if (newIds.length > 0) {
        const idsChunks = divideArrayIntoChunks(newIds, 40)
        const responses = await Promise.all(
          idsChunks.map((ids) =>
            evmTokenClient.v1.nft.$get({
              query: { id: [...ids] },
            }),
          ),
        )
        for (const response of responses) {
          if (!response.ok) {
            throw new Error(
              `Failed to get nfts: ${response.status} ${response.statusText}`,
            )
          }
          const result = await response.json()
          for (const nft of result) {
            nftCache.set(nft.id, nft)
          }
        }
      }
      return ids.map(
        (id) => nftCache.get(id) ?? { type: 'Nft', id, updatedAt: 0 },
      )
    },
  }
}
