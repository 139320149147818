import type { Chain } from '@0xtorch/evm'
import {
  createArbitrumOneChain,
  createAstarChain,
  createAstarZkEvmChain,
  createAvalancheCChain,
  createBaseChain,
  createBlastChain,
  createBscChain,
  createChainVerseChain,
  createClient,
  createDefiVerseChain,
  createEthereumChain,
  createFantomChain,
  createGeekVerseChain,
  createGesoVerseChain,
  createHomeVerseChain,
  createLineaChain,
  createMantaPacificChain,
  createMantleChain,
  createMchVerseChain,
  createMchVerseChainCustom,
  createMetisChain,
  createMoonbeamChain,
  createMoonriverChain,
  createOasysChain,
  createOpBnbChain,
  createOptimismChain,
  createPolygonPosChain,
  createPolygonZkEvmChain,
  createRoninChain,
  createSaakuruChain,
  createScrollChain,
  createSoneiumChain,
  createSonicChain,
  createTaikoChain,
  createTcgVerseChain,
  createYooldoVerseChain,
  createZkFairChain,
  createZkSyncEraChain,
  createZoraChain,
  mchVerseHttpRpcs,
} from '@0xtorch/evm'
import { defineChain } from 'viem'

type CreateEvmChainListParameters = {
  proxyApiEndpoint?: string
  arbitrumOneApiKey: string
  avalancheCChainApiKey: string
  baseApiKey: string
  bscApiKey: string
  blastApiKey: string
  ethereumApiKey: string
  fantomApiKey: string
  lineaApiKey: string
  mantleApiKey: string
  moonbeamApiKey: string
  moonriverApiKey: string
  oasysProxyUrl?: string
  opBnbApiKey: string
  optimismApiKey: string
  polygonPosApiKey: string
  polygonZkEvmApiKey: string
  roninApiKey: string
  scrollApiKey: string
  sonicApiKey: string
  taikoApiKey: string
  zksyncApiKey: string
  zkfairProxyUrl?: string
}

export const createEvmChainList = ({
  proxyApiEndpoint,
  arbitrumOneApiKey,
  avalancheCChainApiKey,
  baseApiKey,
  bscApiKey,
  blastApiKey,
  ethereumApiKey,
  fantomApiKey,
  lineaApiKey,
  mantleApiKey,
  moonbeamApiKey,
  moonriverApiKey,
  oasysProxyUrl,
  opBnbApiKey,
  optimismApiKey,
  polygonPosApiKey,
  polygonZkEvmApiKey,
  roninApiKey,
  scrollApiKey,
  sonicApiKey,
  taikoApiKey,
  zksyncApiKey,
  zkfairProxyUrl,
}: CreateEvmChainListParameters): readonly Chain[] => [
  createArbitrumOneChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: arbitrumOneApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createAstarChain({}),
  createAstarZkEvmChain({}),
  createAvalancheCChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: avalancheCChainApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createBaseChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: baseApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createBscChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: bscApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createBlastChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: blastApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createChainVerseChain({}),
  createDefiVerseChain({}),
  createEthereumChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: ethereumApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createFantomChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: fantomApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createGeekVerseChain({}),
  createGesoVerseChain({}),
  createHomeVerseChain({}),
  createLineaChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: lineaApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createMantaPacificChain({ explorerProxyUrl: proxyApiEndpoint }),
  createMantleChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: mantleApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  proxyApiEndpoint === undefined
    ? createMchVerseChain({})
    : createMchVerseChainCustom({
        client: createClient({
          chain: defineChain({
            id: 29548,
            name: 'MCH Verse',
            nativeCurrency: { name: 'Oasys', symbol: 'OAS', decimals: 18 },
            rpcUrls: {
              default: {
                http: ['https://rpc.oasys.mycryptoheroes.net'],
              },
            },
            blockExplorers: {
              default: {
                name: 'MCH Verse Explorer',
                url: 'https://explorer.oasys.mycryptoheroes.net',
                apiUrl: 'https://explorer.oasys.mycryptoheroes.net/api',
              },
            },
            testnet: false,
          }),
          httpRpcs: mchVerseHttpRpcs.map((rpc) => ({
            ...rpc,
            url: `${proxyApiEndpoint.replace('?url=', '/rpc?url=')}${rpc.url}`,
          })),
        }),
        explorer: createMchVerseChain({}).explorer,
      }),
  createMetisChain({
    explorerProxyUrl: proxyApiEndpoint,
  }),
  createMoonbeamChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: moonbeamApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createMoonriverChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: moonriverApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createOasysChain(
    proxyApiEndpoint === undefined
      ? { explorerProxyUrl: oasysProxyUrl }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createOpBnbChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: opBnbApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createOptimismChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: optimismApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createPolygonPosChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: polygonPosApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createPolygonZkEvmChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: polygonZkEvmApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createRoninChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: roninApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createSaakuruChain({}),
  createScrollChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: scrollApiKey, explorerPageSize: 1000 }
      : { explorerProxyUrl: proxyApiEndpoint, explorerPageSize: 1000 },
  ),
  createSoneiumChain({}),
  createSonicChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: sonicApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createTaikoChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: taikoApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createTcgVerseChain({ explorerProxyUrl: proxyApiEndpoint }),
  createYooldoVerseChain({}),
  createZkFairChain({ explorerProxyUrl: zkfairProxyUrl }),
  createZkSyncEraChain(
    proxyApiEndpoint === undefined
      ? { explorerApiKey: zksyncApiKey }
      : { explorerProxyUrl: proxyApiEndpoint },
  ),
  createZoraChain({}),
]
