import {
  type Action,
  type CryptoCurrencyDataSource,
  type FiatCurrency,
  setActionPrices,
} from '@0xtorch/core'
import type { Chain, Hex, LowerHex, TransactionDecoded } from '@0xtorch/evm'
import {
  FailedToAnalyzeEvmChainException,
  FailedToAnalyzeEvmTxException,
  FailedToFetchEvmIndexDataException,
  FailedToFetchEvmTxException,
  divideArrayIntoChunks,
} from '@pkg/basic'
import type { AccountEvm } from '@pkg/database-portfolio'
import {
  getEvmTxIndexes,
  ignoreLockedActionEvmTxs,
  saveEvmTxAndActions,
  saveEvmTxIndexes,
} from '@pkg/database-portfolio'
import type { AssetFetchClient } from '@pkg/datasource-client'
import { createEvmAnalyzeDataSource } from './createEvmAnalyzeDataSource'
import { createEvmDatasourceCache } from './createEvmDatasourceCache'
import type {
  AnalyzeEvmTransactionParameters,
  FetchEvmAddressDataParameters,
  FetchEvmAddressDataReturnTypes,
  FetchEvmTransactionsParameters,
  FetchEvmTransactionsReturnTypes,
  RunToDatabase,
} from './types'

const analyzingEvmChains = new Set<number>()

type AnalyzeEvmAccountsParameters = {
  readFromDatabase: RunToDatabase
  writeToDatabase: RunToDatabase
  getToken?: () => Promise<string | null>
  startTime: number
  chain: Chain
  accountList: readonly AccountEvm[]
  allEvmAccountAddressList: readonly LowerHex[]
  fiatCurrency: FiatCurrency
  cryptoCurrencyDataSource: CryptoCurrencyDataSource
  assetFetchClient: AssetFetchClient
  datasourceApiEndpoint: string
  evmTokenApiEndpoint: string
  fetchAddressData: (
    parameters: FetchEvmAddressDataParameters,
  ) => Promise<FetchEvmAddressDataReturnTypes>
  fetchTransactions: (
    parameters: FetchEvmTransactionsParameters,
  ) => Promise<FetchEvmTransactionsReturnTypes>
  analyzeTransaction: (parameters: AnalyzeEvmTransactionParameters) => Promise<{
    readonly transaction: TransactionDecoded
    readonly actions: readonly Action[]
  }>
  onStart: (accountIdSet: Set<number>) => void
  onEnd: () => void
  onFetchedAccount: (accountId: number) => void
  onStartAnalyzeTransaction: (transactionHashSet: Set<Hex>) => void
  onFetchedTransaction: (transactionHash: Hex) => void
  onAnalyzedTransaction: (transactionHash: Hex) => void
  onError: (error: unknown) => void
}

export const analyzeEvmAccounts = async ({
  readFromDatabase,
  writeToDatabase,
  getToken,
  startTime,
  chain,
  accountList,
  allEvmAccountAddressList,
  fiatCurrency,
  cryptoCurrencyDataSource,
  assetFetchClient,
  datasourceApiEndpoint,
  evmTokenApiEndpoint,
  fetchAddressData,
  fetchTransactions,
  analyzeTransaction,
  onStart,
  onEnd,
  onFetchedAccount,
  onStartAnalyzeTransaction,
  onFetchedTransaction,
  onAnalyzedTransaction,
  onError,
}: AnalyzeEvmAccountsParameters): Promise<void> => {
  if (analyzingEvmChains.has(chain.id)) {
    return
  }
  analyzingEvmChains.add(chain.id)
  onStart(new Set(accountList.map(({ id }) => id)))

  try {
    // Account 毎の index & internal transaction data を取得
    for (const account of accountList) {
      const addressDataResult = await fetchAddressData({
        chain,
        account,
        getToken,
      }).catch((error) => {
        onError(
          new FailedToFetchEvmIndexDataException(
            error,
            chain.name,
            account.evmAddress,
          ),
        )
        return undefined
      })
      if (addressDataResult !== undefined) {
        const {
          internalTransactions,
          indexes,
          erc20Transfers,
          transactionCount,
        } = addressDataResult

        // erc20 transfer から erc20 token を取得
        const erc20TokenAddresses = new Set<LowerHex>()
        for (const { address, value } of erc20Transfers) {
          if (value === 0n) {
            continue
          }
          erc20TokenAddresses.add(address)
        }
        const datasource = createEvmAnalyzeDataSource({
          assetFetchClient,
          datasourceApiEndpoint,
          evmTokenApiEndpoint,
        })
        const erc20Tokens =
          erc20TokenAddresses.size === 0
            ? []
            : await datasource.getErc20Tokens({
                chainId: chain.id,
                addresses: [...erc20TokenAddresses],
              })

        // 取得した index data (internal transaction 含む) を DB に保存
        // start time 以前の tx の index data は除外
        // erc20 token, account erc20 token relation も DB に保存
        // account.evmToBlock も最新 block に更新
        await writeToDatabase((database) =>
          saveEvmTxIndexes({
            database,
            accountId: account.id,
            chainId: chain.id,
            ...addressDataResult,
            internalTransactions,
            txIndexes: indexes.filter(
              ({ timestamp }) => timestamp >= startTime,
            ),
            erc20Tokens,
            transactionCount,
          }),
        )
      }

      // account data 取得完了 callback
      onFetchedAccount(account.id)
    }

    // analyzed = false な tx index data 及び関連 internal transaction data を DB から取得
    const { indexes, internalTransactions } = await readFromDatabase(
      (database) =>
        getEvmTxIndexes({
          database,
          chainId: chain.id,
          analyzed: false,
        }),
    )

    // indexes.length が 0 の場合は終了
    if (indexes.length === 0) {
      return
    }

    // 該当 tx を source とする isLocked=true の action が存在する tx は除外する
    // 除外した tx について、 accountEvmIndexRelation.analyzed を true に更新
    const ignoreHashes = await writeToDatabase((database) =>
      ignoreLockedActionEvmTxs({
        database,
        chainId: chain.id,
        hashes: indexes.map(({ hash }) => hash),
      }),
    )
    const targetIndexes = indexes.filter(({ hash }) => !ignoreHashes.has(hash))

    console.debug(`${chain.network}'s targetIndexes:`)
    console.debug(targetIndexes)

    // targetIndexes が空の場合は終了
    if (targetIndexes.length === 0) {
      return
    }

    // transaction 解析開始 callback
    onStartAnalyzeTransaction(new Set(targetIndexes.map(({ hash }) => hash)))

    // filter をかけた index data を 100件毎に分割して100件毎処理
    const chunkSize = 100
    for (const indexChunk of divideArrayIntoChunks(targetIndexes, chunkSize)) {
      // transaction detail 一括取得
      const { transactions, errors } = await fetchTransactions({
        chain,
        indexes: indexChunk,
        internalTransactionList: internalTransactions,
        onFetched: onFetchedTransaction,
      })
      for (const [hash, error] of errors.entries()) {
        onError(new FailedToFetchEvmTxException(error, chain.name, hash))
      }

      // datasource cache 作成
      await createEvmDatasourceCache({
        assetFetchClient,
        datasourceApiEndpoint,
        evmTokenApiEndpoint,
        chainId: chain.id,
        transactionList: transactions,
        accountAddresses: new Set(allEvmAccountAddressList),
      })

      const decodedTxs: TransactionDecoded[] = []
      const actions: Action[] = []

      // tx 1件ずつ解析
      for (const transaction of transactions) {
        try {
          const { transaction: decodedTx, actions: generatedActions } =
            await analyzeTransaction({
              chain,
              transaction,
              assetFetchClient,
              datasourceApiEndpoint,
              evmTokenApiEndpoint,
              accountAddressList: allEvmAccountAddressList,
            })
          decodedTxs.push(decodedTx)
          actions.push(...generatedActions)
        } catch (error) {
          onError(
            new FailedToAnalyzeEvmTxException(
              error,
              chain.name,
              transaction.hash,
            ),
          )
        }
        // transaction 解析完了 callback
        onAnalyzedTransaction(transaction.hash)
      }

      // 生成した action の価格設定
      const pricedActions = await setActionPrices({
        actions,
        fiat: fiatCurrency,
        dataSource: cryptoCurrencyDataSource,
      })

      // - rule に合わせて action 更新
      // - DB に action 保存 & 関連データ更新
      // portfolio.isTxGenerated を false に更新
      // accountEvmIndexRelation.analyzed を true に更新
      // decoded transaction を actionSource に保存
      // action を保存・ rule があれば rule に合わせて更新
      await writeToDatabase((database) =>
        saveEvmTxAndActions({
          database,
          chainId: chain.id,
          transactions: decodedTxs,
          actions: pricedActions,
        }),
      )
    }
  } catch (error) {
    onError(new FailedToAnalyzeEvmChainException(error, chain.name))
  } finally {
    // 処理完了時に解析中から削除する
    analyzingEvmChains.delete(chain.id)

    // 処理完了を callback
    onEnd()
  }
}
