import type {
  CryptoCurrency,
  CryptoCurrencyDataSource,
  FiatCurrency,
} from '@0xtorch/core'
import {
  FailedToAnalyzeExchangeApiException,
  FailedToAnalyzeExchangeException,
} from '@pkg/basic'
import type {
  AccountExchange,
  AccountExchangeApi,
  InsertAccountExchangeApiData,
  InsertAccountExchangeData,
  InsertSingleActionRulesData,
} from '@pkg/database-portfolio'
import type {
  AccountExchangeWithApi,
  RunToDatabase,
  SaveExchangeApiDataParameters,
} from './types'

const analyzingExchanges = new Set<string>()

type AnalyzeExchangeAccountsParameters = {
  readFromDatabase: RunToDatabase
  writeToDatabase: RunToDatabase
  getToken?: () => Promise<string | null>
  startTime: number
  category: string
  apiIdList: readonly string[]
  accountList: readonly AccountExchangeWithApi[]
  cryptoCurrencyList: readonly CryptoCurrency[]
  fiatCurrency: FiatCurrency
  fiatCurrencyList: readonly FiatCurrency[]
  cryptoCurrencyDataSource: CryptoCurrencyDataSource
  proxyApiEndpoint: string
  saveApiData: (parameters: SaveExchangeApiDataParameters) => Promise<void>
  onStart: (
    accountApiIdSetList: {
      readonly accountId: number
      readonly apiIdSet: Set<string>
    }[],
  ) => void
  onEnd: () => void
  onFetchedCallSize: (values: {
    readonly accountId: number
    readonly apiId: string
    readonly size: number
  }) => void
  onCalled: (values: {
    readonly accountId: number
    readonly apiId: string
  }) => void
  onFetchedApi: (values: {
    readonly accountId: number
    readonly apiId: string
  }) => void
  onError: (error: unknown) => void
}

export const analyzeExchangeAccounts = async ({
  readFromDatabase,
  writeToDatabase,
  getToken,
  startTime,
  category,
  apiIdList,
  accountList,
  cryptoCurrencyList,
  fiatCurrency,
  fiatCurrencyList,
  cryptoCurrencyDataSource,
  proxyApiEndpoint,
  saveApiData,
  onStart,
  onEnd,
  onFetchedCallSize,
  onCalled,
  onFetchedApi,
  onError,
}: AnalyzeExchangeAccountsParameters): Promise<void> => {
  if (analyzingExchanges.has(category)) {
    return
  }
  analyzingExchanges.add(category)
  onStart(
    accountList.map((account) => ({
      accountId: account.id,
      apiIdSet: new Set(apiIdList),
    })),
  )

  try {
    for (const account of accountList) {
      for (const apiId of apiIdList) {
        // 最新取得済 timestamp から現在時刻までのデータ取得＆保存
        await saveApiData({
          readFromDatabase,
          writeToDatabase,
          getToken,
          startTime,
          account,
          apiId,
          accountApis: account.apiList,
          proxyApiEndpoint,
          cryptoCurrencyList,
          fiatCurrency,
          fiatCurrencyList,
          cryptoCurrencyDataSource,
          onFetchedCallSize: (size) => {
            onFetchedCallSize({ accountId: account.id, apiId, size })
          },
          onCalled: () => {
            onCalled({ accountId: account.id, apiId })
          },
        }).catch((error) => {
          onError(
            new FailedToAnalyzeExchangeApiException(error, category, apiId),
          )
        })

        onFetchedApi({ accountId: account.id, apiId })
      }
    }
  } catch (error) {
    onError(new FailedToAnalyzeExchangeException(error, category))
  } finally {
    // 処理完了時に解析中 exchange account を空にする
    analyzingExchanges.delete(category)

    // 処理完了を callback
    onEnd()
  }
}

export const createRuleDataListBySourceList = (
  sourceList: readonly string[],
): readonly InsertSingleActionRulesData[] => {
  const mut_ruleDataList: InsertSingleActionRulesData[] = []
  for (const source of sourceList) {
    mut_ruleDataList.push(
      // `income` to `receive-from-cex`
      {
        source,
        targetType: 'income',
        newType: 'receive-from-cex',
      },
      // `fee` to `send-to-cex`
      {
        source,
        targetType: 'fee',
        newType: 'send-to-cex',
      },
      // `transfer` to `send-to-cex`
      {
        source,
        targetType: 'transfer',
        newType: 'send-to-cex',
      },
    )
  }
  return mut_ruleDataList
}
