import type {
  AccountCategory,
  AccountCategoryBlockchain,
  AccountCategoryExchange,
  AccountCategoryService,
  AccountCategoryWallet,
} from '../types/accountCategory'
import { blankApiKeyEvmChainList } from './evmChain'
import { blankApiKeyPolkadotChains } from './polkadotChain'

export const exchangeCategoryList: readonly AccountCategoryExchange[] = [
  {
    id: 'binance',
    type: 'exchange',
    name: 'Binance',
    icon: '/images/binance.svg',
    url: 'https://www.binance.com',
    exchangeApiKey: true,
    exchangeApiSecret: true,
    // TODO 一時的にコメントアウト
    // exchangeApis: ['deposit', 'withdraw'],
    apiGuideUrl:
      'https://docs.google.com/document/d/1WELjWy-wLZJ90qNIqoIiYNdMImToxIjPphT2ShWoHn4/edit?usp=sharing',
    csvs: [
      'binance-convert',
      'binance-fiat-deposit-withdrawal-jst',
      'binance-future-transactions',
      'binance-spot-trade',
      'binance-transaction-record',
      'binance-withdraw',
      'binance-withdraw-2',
      'binance-withdrawal-ja',
    ],
    csvGuideUrl:
      'https://docs.google.com/document/d/1Ln-JZx1WPDHX4vGfulgDpdtSjd56YfY_mVrRCjyzPf0/edit?usp=sharing',
  },
  {
    id: 'bitbank',
    type: 'exchange',
    name: 'bitbank',
    icon: '/images/bitbank.svg',
    url: 'https://bitbank.cc',
    exchangeApiKey: true,
    exchangeApiSecret: true,
    exchangeApis: ['deposit', 'withdrawal', 'trade'],
    apiGuideUrl:
      'https://docs.google.com/document/d/1urhxiZBVWL9CFx47DYV_Udpq3rFStBc3WfUTirESKIQ/edit?usp=sharing',
    csvs: [
      'bitbank-dealer',
      'bitbank-spot-trade',
      'bitbank-trade',
      'bitbank-withdrawal-all',
      'bitbank-withdrawal-jpy',
    ],
    csvGuideUrl:
      'https://docs.google.com/document/d/1iHhqqKr8ER0uF1wB3qvOnXfWuCNUEwO2ZhBMWcpONJ8/edit?usp=sharing',
  },
  {
    id: 'bitget',
    type: 'exchange',
    name: 'Bitget',
    icon: '/images/bitget.svg',
    url: 'https://www.bitget.com',
    exchangeApiKey: true,
    exchangeApiSecret: true,
    exchangeApiPassword: true,
    exchangeApis: ['deposit', 'future-tax', 'spot-tax', 'withdrawal'],
    apiGuideUrl:
      'https://docs.google.com/document/d/1bnZBgRHZkDFYBaEYNJ1qj8An-sE_HqhwnU05Ivdr_rI/edit?usp=sharing',
    csvs: [
      'bitget-spot-jst',
      'bitget-spot-jst-202412',
      'bitget-spot-trade-jst',
      'bitget-spot-trade-jst-202412',
      'bitget-future-trade',
      'bitget-future-trade-202412',
      'bitget-saving',
      'bitget-saving-202412',
    ],
    csvGuideUrl:
      'https://docs.google.com/document/d/1r2RhYbAGYOvlOw4t_N3IH43GReNhhq94FMHLS6yhEqs/edit?usp=sharing',
  },
  {
    id: 'bitflyer',
    type: 'exchange',
    name: 'bitFlyer',
    icon: '/images/bitflyer.svg',
    url: 'https://bitflyer.com',
    csvs: ['bitflyer-lightning-collateral', 'bitflyer-trade'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1KCPTSI4SSxwrqkQt4VwrmzB-vGaWjzERIlbnSm6nbA8/edit?usp=sharing',
  },
  {
    id: 'bitpoint',
    type: 'exchange',
    name: 'BITPOINT',
    icon: '/images/bitpoint.svg',
    url: 'https://www.bitpoint.co.jp',
    csvs: ['bitpoint-spot'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1NPgpABURpy1hh6duZ6Muewn4N_fkRYOA_p4wdCGlnqo/edit?usp=sharing',
  },
  {
    id: 'bittrade',
    type: 'exchange',
    name: 'BitTrade',
    icon: '/images/bittrade.svg',
    url: 'https://www.bittrade.co.jp',
    csvs: [
      'bittrade-deposit-withdrawal',
      'bittrade-spot-trade',
      'bittrade-withdrawal-jpy',
    ],
    csvGuideUrl:
      'https://docs.google.com/document/d/1t6uAY_yKvy7v3LrJi3c68F3sJMjhgmBGqj-i6BpPFRQ/edit?usp=sharing',
  },
  {
    id: 'bybit',
    type: 'exchange',
    name: 'Bybit',
    icon: '/images/bybit.svg',
    url: 'https://www.bybit.com',
    exchangeApiKey: true,
    exchangeApiSecret: true,
    exchangeApis: ['deposit', 'linear-closed-pl', 'spot-trade', 'withdrawal'],
    apiGuideUrl:
      'https://docs.google.com/document/d/1zVXXFOnZKQSh3qcqXsRoYIHzzBdGkli8DsYANyiNAwc/edit?usp=sharing',
    csvs: [
      'bybit-funding-account',
      'bybit-future-pl',
      'bybit-future-pl-before-uta',
      'bybit-option-delivary',
      'bybit-spot-trade',
      'bybit-spot-trade-2',
      'bybit-spot-trade-before-uta',
      'bybit-statement-account-contract',
      'bybit-statement-account-copytrading',
      'bybit-statement-account-spot',
      'bybit-statement-account-uta',
    ],
    csvGuideUrl:
      'https://docs.google.com/document/d/1WqEMsHHb-Z_2YdfLqdlG9Fcbfb3dGFrjaNvkQGbWrWA/edit?usp=sharing',
  },
  {
    id: 'btcbox',
    type: 'exchange',
    name: 'BTCBOX',
    icon: '/images/btcbox.svg',
    url: 'https://www.btcbox.co.jp',
    csvs: [
      'btcbox-history-bch',
      'btcbox-history-btc',
      'btcbox-history-eth',
      'btcbox-history-ltc',
    ],
    csvGuideUrl:
      'https://docs.google.com/document/d/1rcUH9JoHJxyNHXFMb0ixRosgRx25GJv817nbD545So4/edit?usp=sharing',
  },
  {
    id: 'coincheck',
    type: 'exchange',
    name: 'Coincheck',
    icon: '/images/coincheck.svg',
    url: 'https://coincheck.com',
    csvs: ['coincheck-standard', 'coincheck-standard-2'],
    csvGuideUrl:
      'https://docs.google.com/document/d/15Tiw7KKqcAAagJGAHi3Haiw6FoMMjONSakYFtEmB294/edit?usp=sharing',
  },
  {
    id: 'cointrade',
    type: 'exchange',
    name: 'CoinTrade',
    icon: '/images/cointrade.svg',
    url: 'https://coin-trade.cc',
    csvs: ['cointrade-cashflow', 'cointrade-trade'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1yXO6ViEIh3qky7nhOptX9mrF0I3OznX3CF9PpUsUqig/edit?usp=sharing',
  },
  {
    id: 'dmmbitcoin',
    type: 'exchange',
    name: 'DMMビットコイン',
    icon: '/images/dmmbitcoin.svg',
    url: 'https://bitcoin.dmm.com',
    csvs: ['dmmbitcoin-cashflow', 'dmmbitcoin-trade'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1MEbn4FYw-2sKA3qOMBHrzB1s_QYCZiWH3friIH1NkAU/edit?usp=sharing',
  },
  {
    id: 'ftx-japan',
    type: 'exchange',
    name: 'FTX Japan',
    icon: '/images/ftx-japan.svg',
    url: 'https://www.liquid.com/ja',
    csvs: ['ftx-japan-pdf'],
  },
  {
    id: 'fxgt',
    type: 'exchange',
    name: 'FXGT',
    icon: '/images/fxgt.svg',
    url: 'https://fxgt.com',
    csvs: [
      'fxgt-deal-btc-p2',
      'fxgt-deal-btc-p3',
      'fxgt-deal-jpy-p2',
      'fxgt-deal-jpy-p3',
      'fxgt-deal-usdt-p2',
      'fxgt-deal-usdt-p3',
    ],
    csvGuideUrl:
      'https://docs.google.com/document/d/1UAI5E9tlDAJRIye3hHM_BH4zRs_xutKezL0lhbY2xfY/edit?usp=sharing',
  },
  {
    id: 'gate',
    type: 'exchange',
    name: 'Gate.io',
    icon: '/images/gate.svg',
    url: 'https://www.gate.io',
    exchangeApiKey: true,
    exchangeApiSecret: true,
    exchangeApis: ['deposit', 'withdrawal', 'spot-trade'],
    apiGuideUrl:
      'https://docs.google.com/document/d/1uW3Vg_gGwIABM2CBWmpwWXCPHqK2PJXRLGZ2VMtg1v0/edit?usp=sharing',
    csvs: [
      'gate-future-action',
      'gate-spot-action',
      'gate-spot-trade',
      'gate-spot-trade-2',
      'gate-withdrawal',
    ],
    csvGuideUrl:
      'https://docs.google.com/document/d/1gkjUrAkcyqVoJdlRHIhJVCGSDcOtKYoQbcAyvSWEkvw/edit?usp=sharing',
  },
  {
    id: 'gmo-coin',
    type: 'exchange',
    name: 'GMOコイン',
    icon: '/images/gmo-coin.svg',
    url: 'https://coin.z.com',
    csvs: ['gmo-coin-statement'],
    csvGuideUrl:
      'https://docs.google.com/document/d/16lTs9pkzJ4FVqZLrHTLYneRKZmfDyMdQ__7nrgxDL8c/edit?usp=sharing',
  },
  {
    id: 'hashkey-global',
    type: 'exchange',
    name: 'HashKey Global',
    icon: '/images/hashkey-global.svg',
    url: 'https://global.hashkey.com',
    csvs: [
      'hashkey-global-future-trade-detail',
      'hashkey-global-spot-trade-detail',
      'hashkey-global-withdrawal',
    ],
    csvGuideUrl:
      'https://docs.google.com/document/d/1tpsk5JxIRlGYK9rAwnLZsA7WFjQAl_1DCFNTgrxnLH4/edit?usp=sharing',
  },
  {
    id: 'htx',
    type: 'exchange',
    name: 'HTX (Huobi Global)',
    icon: '/images/htx.svg',
    url: 'https://www.htx.com',
    csvs: ['htx-exchange-jst'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1tVGMHPNbXvqC6Kea0pIo5q6fZ_D6IvrrvOAsags8B4Q/edit?usp=sharing',
  },
  {
    id: 'hyperliquid',
    type: 'exchange',
    name: 'Hyperliquid',
    icon: '/images/hyperliquid.svg',
    url: 'https://app.hyperliquid.xyz',
    csvs: [
      'hyperliquid-deposit-withdrawal-jst',
      'hyperliquid-funding-history-jst',
      'hyperliquid-staking-reward-history',
      'hyperliquid-trade-history-jst',
    ],
    csvGuideUrl:
      'https://docs.google.com/document/d/1T-a7kfG2jt2fymCNHnbrv8n1okHoSso1TNfsJj_bpRU/edit?usp=sharing',
  },
  {
    id: 'kucoin',
    type: 'exchange',
    name: 'KuCoin',
    icon: '/images/kucoin.svg',
    url: 'https://www.kucoin.com',
    exchangeApiKey: true,
    exchangeApiSecret: true,
    exchangeApiPassword: true,
    exchangeApis: ['deposit', 'withdrawal', 'spot-trade'],
    apiGuideUrl:
      'https://docs.google.com/document/d/12DUAhzIsBMT_RMk0TjoWB-mMAXvscVGuF3aHZS8Q5HU/edit?usp=sharing',
    csvs: [
      'kucoin-earn-profit-jst',
      'kucoin-funding-account-jst',
      'kucoin-future-pl-jst',
      'kucoin-margin-trade-jst',
      'kucoin-spot-trade-jst',
      'kucoin-withdrawal-jst',
    ],
    csvGuideUrl:
      'https://docs.google.com/document/d/1mFJo6wdeO6t_UZDP6p7xawrN3ECWBpq6qIuRO4KPZLU/edit?usp=sharing',
  },
  {
    id: 'liquid',
    type: 'exchange',
    name: 'Liquid by Quoine',
    icon: '/images/liquid.svg',
    url: 'https://www.liquid.com',
    csvs: ['liquid-trade'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1PcKjV5E-5spXlHkewSJ_HTxK8CKoLF2qrXNToe7vdDQ/edit?usp=sharing',
  },
  {
    id: 'mexc',
    type: 'exchange',
    name: 'MEXC',
    icon: '/images/mexc.svg',
    url: 'https://www.mexc.com',
    csvs: [
      'mexc-funding-others-ja',
      'mexc-future-ja-jst',
      'mexc-future-ja-p8',
      'mexc-future-flow-ja-jst',
      'mexc-spot-trade',
      'mexc-withdrawal-ja',
    ],
    csvGuideUrl:
      'https://docs.google.com/document/d/1TbmveO9WbhVldHH0iw2hh2zRS9IJ4WM8YzCAT9dERKw/edit?usp=sharing',
  },
  {
    id: 'nexo',
    type: 'exchange',
    name: 'Nexo',
    icon: '/images/nexo.svg',
    url: 'https://nexo.com',
    csvs: ['nexo-transaction'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1_HURGeO70alTaV22qFfKJHlwPVhamrdlN5-Ry009dWA/edit?usp=sharing',
  },
  {
    id: 'okj',
    type: 'exchange',
    name: 'OKJ (OKCoin Japan)',
    icon: '/images/okj.svg',
    url: 'https://www.okcoin.jp',
    csvs: ['okj-trade', 'okj-deposit-withdrawal'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1IbIvoURiQxTacdpn_KY9o2npFVDqFhp5aVsCqktj6eA/edit?usp=sharing',
  },
  {
    id: 'okx',
    type: 'exchange',
    name: 'OKX',
    icon: '/images/okx.svg',
    url: 'https://www.okx.com',
    exchangeApiKey: true,
    exchangeApiSecret: true,
    exchangeApiPassword: true,
    exchangeApis: ['deposit', 'withdrawal', 'spot-trade'],
    csvs: ['okx-funding-jst', 'okx-trade-jst-2', 'okx-trade-jst'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1VivP85ACdUPc_mbKvwUk6XanisHvPtbjcUP7-Bayw0c/edit?usp=sharing',
  },
  {
    id: 'rakuten-wallet',
    type: 'exchange',
    name: '楽天ウォレット',
    icon: '/images/rakuten-wallet.svg',
    url: 'https://www.rakuten-wallet.co.jp',
    csvs: ['rakuten-wallet-spot'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1oOBTIwdhOyvQo7qflJnZQnHsF4dytL2K6lCAObnbDMg/edit?usp=sharing',
  },
  {
    id: 'sbivc-trade',
    type: 'exchange',
    name: 'SBI VC トレード',
    icon: '/images/sbivc-trade.svg',
    url: 'https://www.sbivc.co.jp',
    csvs: ['sbivc-trade-cashflow', 'sbivc-trade-trade'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1pQN8oByidldZdpKaJ_JmATzVT4JewwUBjOipLDZDwkw/edit?usp=sharing',
  },
  {
    id: 'spectrocoin',
    type: 'exchange',
    name: 'SpectroCoin',
    icon: '/images/spectrocoin.svg',
    url: 'https://spectrocoin.com',
    csvs: ['spectrocoin-transactions'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1OTWhmHSZTNblw0SeVVXFZRsvw_Q6PmY0-g1taDQWh2A/edit?usp=sharing',
  },
  {
    id: 'zaif',
    type: 'exchange',
    name: 'Zaif',
    icon: '/images/zaif.svg',
    url: 'https://zaif.jp',
    csvs: ['zaif-fx-trade', 'zaif-margin-trade', 'zaif-trade'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1KsIe11cGZJ84MRKzrTuWUQfYJOC35rty5vfzOC7q9TE/edit?usp=sharing',
  },
  {
    id: 'zoomex',
    type: 'exchange',
    name: 'Zoomex',
    icon: '/images/zoomex.svg',
    url: 'http://zoomex.com',
    csvs: [
      'zoomex-derivative-pl',
      'zoomex-derivative-pl-2',
      'zoomex-withdrawal-ja',
    ],
    csvGuideUrl:
      'https://docs.google.com/document/d/1PSYQXQIlhtSfEd_1CejRuB6rYP1Ht___6rdGYUESdzI/edit?usp=sharing',
  },
]

export const walletCategoryList: readonly AccountCategoryWallet[] = [
  {
    id: 'metamask',
    type: 'wallet',
    name: 'MetaMask',
    icon: '/images/metamask.svg',
    url: 'https://metamask.io',
  },
  {
    id: 'rabby',
    type: 'wallet',
    name: 'Rabby',
    icon: '/images/rabby.svg',
    url: 'https://rabby.io',
  },
  {
    id: 'phantom',
    type: 'wallet',
    name: 'Phantom',
    icon: '/images/phantom.svg',
    url: 'https://phantom.app',
  },
]

export const serviceCategoryList: readonly AccountCategoryService[] = [
  {
    id: 'cryptorch',
    type: 'service',
    name: 'Cryptorch',
    icon: 'icon.svg',
    url: 'https://portfolio.cryptorch.dev',
    csvs: ['cryptorch-transaction-jp'],
  },
  {
    id: 'cryptact',
    type: 'service',
    name: 'cryptact',
    icon: '/images/cryptact.svg',
    url: 'https://www.cryptact.com',
    csvs: ['cryptact-ledger-ja'],
  },
  {
    id: 'gtax',
    type: 'service',
    name: 'Gtax',
    icon: '/images/gtax.svg',
    url: 'https://www.cryptact.com',
    csvs: ['gtax-common-format', 'gtax-history'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1WnaRDcDD56OsZ6riMi7ci02pfX9XqQaW0y90xHV_2Io/edit?usp=sharing',
  },
  {
    id: 'hashhub-lending',
    type: 'service',
    name: 'HashHub Lending',
    icon: '/images/hashhub-lending.svg',
    url: 'https://www.hashhub-lending.com',
    csvs: ['hashhub-lending-history'],
    csvGuideUrl:
      'https://docs.google.com/document/d/1cHqf6tnhCcRwiM7tYntLI1B_QZMCPDhHlA-qxDFvsu8/edit?usp=sharing',
  },
]

export const blockchainCategoryList: readonly AccountCategoryBlockchain[] = [
  {
    id: 'solana',
    type: 'blockchain',
    name: 'Solana',
    icon: '/images/solana.svg',
    url: 'https://solana.com',
  } satisfies AccountCategoryBlockchain,
  ...blankApiKeyEvmChainList.map(
    (chain): AccountCategoryBlockchain => ({
      id: chain.network,
      type: 'blockchain',
      name: chain.name,
      icon: `/images/${chain.network}.svg`,
      url: chain.explorer.baseUrl,
    }),
  ),
  ...blankApiKeyPolkadotChains.map(
    (chain): AccountCategoryBlockchain => ({
      id: chain.network,
      type: 'blockchain',
      name: chain.name,
      icon: `/images/${chain.network}.svg`,
      url: chain.explorer.baseUrl,
    }),
  ),
].toSorted((a, b) => a.name.localeCompare(b.name))

export const accountCategoryList: readonly AccountCategory[] = [
  ...exchangeCategoryList,
  ...walletCategoryList,
  ...serviceCategoryList,
  ...blockchainCategoryList,
]
