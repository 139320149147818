/** Clerk Auth の token が期限切れ・発行できない場合のエラー */
export class AuthTokenExpiredException extends Error {
  constructor() {
    super('Auth token expired')
    this.name = 'AuthTokenExpiredException'
  }
}

/** アップロードしたファイルが不正な形式の場合のエラー */
export class InvalidFileFormatException extends Error {
  constructor() {
    super('Invalid file format')
    this.name = 'InvalidFileFormatException'
  }
}

/** Readonly mode で DB に書き込みを行おうとした場合のエラー */
export class UnwritableDatabaseException extends Error {
  constructor() {
    super('Database is in readonly mode')
    this.name = 'UnwritableDatabaseException'
  }
}

/** 取引所データ同期に失敗した場合のエラー */
export class FailedToAnalyzeExchangeException extends Error {
  readonly exchangeName: string
  constructor(error: unknown, exchangeName: string) {
    let message: string
    if (error instanceof Error) {
      message = `Failed to analyze exchange data of ${exchangeName}\n\n${error.message}`
    } else {
      message = `Failed to analyze exchange data of ${exchangeName}`
    }
    super(message)
    this.exchangeName = exchangeName
  }
}

export class FailedToAnalyzeExchangeApiException extends Error {
  readonly exchangeName: string
  readonly apiId: string
  constructor(error: unknown, exchangeName: string, apiId: string) {
    let message: string
    if (error instanceof Error) {
      message = `Failed to analyze exchange data of ${exchangeName}.${apiId}\n\n${error.message}`
    } else {
      message = `Failed to analyze exchange data of ${exchangeName}.${apiId}`
    }
    super(message)
    this.exchangeName = exchangeName
    this.apiId = apiId
  }
}

/** EVM Chain データ同期に失敗した場合のエラー */
export class FailedToAnalyzeEvmChainException extends Error {
  readonly chainName: string
  constructor(error: unknown, chainName: string) {
    let message: string
    if (error instanceof Error) {
      message = `Failed to analyze transaction of ${chainName}\n\n${error.message}`
    } else {
      message = `Failed to analyze transaction of ${chainName}`
    }
    super(message)
    this.chainName = chainName
  }
}

/** EVM Chain で index data の取得に失敗した場合のエラー */
export class FailedToFetchEvmIndexDataException extends Error {
  readonly chainName: string
  readonly address: string
  constructor(error: unknown, chainName: string, address: string) {
    let message: string
    if (error instanceof Error) {
      message = `Failed to fetch index data of ${chainName}.${address}\n\n${error.message}`
    } else {
      message = `Failed to fetch index data of ${chainName}.${address}`
    }
    super(message)
    this.chainName = chainName
    this.address = address
  }
}

/** EVM Chain で transaction data の取得に失敗した場合のエラー */
export class FailedToFetchEvmTxException extends Error {
  readonly chainName: string
  readonly hash: string
  constructor(error: unknown, chainName: string, hash: string) {
    let message: string
    if (error instanceof Error) {
      message = `Failed to fetch transaction of ${chainName}.${hash}\n\n${error.message}`
    } else {
      message = `Failed to fetch transaction of ${chainName}.${hash}`
    }
    super(message)
    this.chainName = chainName
    this.hash = hash
  }
}

/** EVM Chain で transaction data の解析に失敗した場合のエラー */
export class FailedToAnalyzeEvmTxException extends Error {
  readonly chainName: string
  readonly hash: string
  constructor(error: unknown, chainName: string, hash: string) {
    let message: string
    if (error instanceof Error) {
      message = `Failed to analyze transaction of ${chainName}.${hash}\n\n${error.message}`
    } else {
      message = `Failed to analyze transaction of ${chainName}.${hash}`
    }
    super(message)
    this.chainName = chainName
    this.hash = hash
  }
}

/** Polkadot Chain データ同期に失敗した場合のエラー */
export class FailedToAnalyzePolkadotChainException extends Error {
  readonly chainName: string
  constructor(error: unknown, chainName: string) {
    let message: string
    if (error instanceof Error) {
      message = `Failed to analyze transaction of ${chainName}\n\n${error.message}`
    } else {
      message = `Failed to analyze transaction of ${chainName}`
    }
    super(message)
    this.chainName = chainName
  }
}

/** Polkadot Chain で index data の取得に失敗した場合のエラー */
export class FailedToFetchPolkadotIndexDataException extends Error {
  readonly chainName: string
  readonly address: string
  constructor(error: unknown, chainName: string, address: string) {
    let message: string
    if (error instanceof Error) {
      message = `Failed to fetch index data of ${chainName}.${address}\n\n${error.message}`
    } else {
      message = `Failed to fetch index data of ${chainName}.${address}`
    }
    super(message)
    this.chainName = chainName
    this.address = address
  }
}

/** Polkadot Chain で block data の取得に失敗した場合のエラー */
export class FailedToFetchPolkadotBlockDataException extends Error {
  readonly chainName: string
  readonly blockNumber: number
  constructor(error: unknown, chainName: string, blockNumber: number) {
    let message: string
    if (error instanceof Error) {
      message = `Failed to fetch block data of ${chainName}.${blockNumber}\n\n${error.message}`
    } else {
      message = `Failed to fetch block data of ${chainName}.${blockNumber}`
    }
    super(message)
    this.chainName = chainName
    this.blockNumber = blockNumber
  }
}

/** Solana Chain データ同期に失敗した場合のエラー */
export class FailedToAnalyzeSolanaException extends Error {
  constructor(error: unknown) {
    let message: string
    if (error instanceof Error) {
      message = `Failed to analyze Solana chain data\n\n${error.message}`
    } else {
      message = 'Failed to analyze Solana chain data'
    }
    super(message)
  }
}

/** Solana Chain transaction data の取得に失敗した場合のエラー */
export class FailedToFetchSolanaAddressException extends Error {
  readonly address: string
  constructor(error: unknown, address: string) {
    let message: string
    if (error instanceof Error) {
      message = `Failed to fetch address data of Solana.${address}\n\n${error.message}`
    } else {
      message = `Failed to fetch transaction of Solana.${address}`
    }
    super(message)
    this.address = address
  }
}

/** Solana Chain transaction data の取得に失敗した場合のエラー */
export class FailedToAnalyzeSolanaTxException extends Error {
  readonly signature: string
  constructor(error: unknown, signature: string) {
    let message: string
    if (error instanceof Error) {
      message = `Failed to analyze transaction of Solana.${signature}\n\n${error.message}`
    } else {
      message = `Failed to analyze transaction of Solana.${signature}`
    }
    super(message)
    this.signature = signature
  }
}

/** Solana Chain transaction data の取得に失敗した場合のエラー */
export class FailedToFetchSolanaTxException extends Error {
  readonly signature: string
  constructor(error: unknown, signature: string) {
    let message: string
    if (error instanceof Error) {
      message = `Failed to fetch transaction of Solana.${signature}\n\n${error.message}`
    } else {
      message = `Failed to fetch transaction of Solana.${signature}`
    }
    super(message)
    this.signature = signature
  }
}
