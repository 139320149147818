import { createEvmChainList } from '../utils/evmChain'

export const blankApiKeyEvmChainList = createEvmChainList({
  arbitrumOneApiKey: '',
  avalancheCChainApiKey: '',
  baseApiKey: '',
  bscApiKey: '',
  blastApiKey: '',
  ethereumApiKey: '',
  fantomApiKey: '',
  lineaApiKey: '',
  mantleApiKey: '',
  moonbeamApiKey: '',
  moonriverApiKey: '',
  opBnbApiKey: '',
  optimismApiKey: '',
  polygonPosApiKey: '',
  polygonZkEvmApiKey: '',
  roninApiKey: '',
  scrollApiKey: '',
  sonicApiKey: '',
  taikoApiKey: '',
  zksyncApiKey: '',
})
