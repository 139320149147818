import { registerNewPortfolio } from '@/features/portfolio/logic/registerNewPortfolio'
import { useToast } from '@/hooks/useToast'
import type { Portfolio } from '@/types'
import { showErrorToast } from '@/utils/error'
import { useAuth } from '@clerk/clerk-react'
import { AuthTokenExpiredException } from '@pkg/basic'
import { useNavigate, useRouterState } from '@tanstack/react-router'
import { ChevronsUpDown, LoaderCircle, Plus } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './ui/dropdownMenu'
import { SidebarMenuButton } from './ui/sidebar'

export const PortfolioSwitcher = (props: {
  portfolio: Portfolio
  portfolios: readonly Portfolio[]
}) => {
  const { portfolio, portfolios } = props
  const { getToken } = useAuth()
  const navigate = useNavigate()
  const { location } = useRouterState()
  const { t } = useTranslation()
  const { toast } = useToast()

  const changePortfolio = async (uuid: string) => {
    if (portfolio.uuid === uuid) {
      return
    }
    await navigate({ to: location.pathname, search: { id: uuid } })
    window.location.reload()
  }

  const [isCreating, setIsCreating] = useState(false)
  const createAndChangePortfolio = async (event: React.MouseEvent) => {
    event.preventDefault()
    const token = await getToken()
    if (token === null) {
      showErrorToast({
        error: new AuthTokenExpiredException(),
        t,
        toast,
      })
      return
    }
    setIsCreating(true)
    try {
      const newPortfolio = await registerNewPortfolio({
        portfolioCount: portfolios.length,
        token,
      })
      await navigate({
        to: '/onboarding',
        search: { id: newPortfolio.uuid },
      })
    } finally {
      setIsCreating(false)
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <SidebarMenuButton size="lg" asChild>
          <div className="flex cursor-pointer items-center">
            <img
              src="/icon.svg"
              alt="Cryptorch"
              className="h-9 w-9 rounded-md"
            />
            <div className="flex flex-1 flex-col overflow-x-hidden">
              <p className="overflow-x-hidden text-ellipsis text-nowrap font-medium">
                {portfolio.name}
              </p>
              <p className="mt-0.5 text-muted-foreground text-xs">
                ID: {portfolio.uuid}
              </p>
            </div>
            <ChevronsUpDown className="h-4 w-4" />
          </div>
        </SidebarMenuButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mt-2 min-w-56 max-w-sm">
        <DropdownMenuRadioGroup
          value={portfolio.uuid}
          onValueChange={changePortfolio}
          className="max-h-[33vh] overflow-y-scroll"
        >
          {portfolios.map((p) => (
            <DropdownMenuRadioItem key={p.uuid} value={p.uuid}>
              <div className="flex flex-col">
                <span>{p.name}</span>
                <span className="mt-0.5 text-muted-foreground text-xs">
                  ID: {p.uuid}
                </span>
              </div>
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={createAndChangePortfolio}
          disabled={isCreating}
        >
          {isCreating ? (
            <LoaderCircle className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            <Plus className="mr-2 h-4 w-4" />
          )}
          <span>Create Portfolio</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default PortfolioSwitcher
